import { User } from 'types/User';
import { APICore } from './apiCore';
import config from "../../config";
import { useApiCallsInterceptors } from './useApiCallsInterceptors';
import { Cotizaciones_RequestConsulta } from 'types/requests/Cotizaciones_RequestConsulta';
import { ClientAccounts_RequestConsulta } from 'types/requests/ClientAccounts_RequestConsulta';
import { ProcessExecution_GetRequest } from 'types/requests/ProcessExecution_GetRequest';
import moment from 'moment';
import { appVersionNumber } from '../../appConstants/einv_config_constants';

export function useApiCalls(url = config.REACT_APP_API_URL) {
    const interceptors = useApiCallsInterceptors();
    const api = new APICore();

    return {
        active: () => {
            return api.getLoggedInUser();
        },
        ClientAccount: {
            get: (filters: ClientAccounts_RequestConsulta) => {
                return api.get(`${url}/clientAccounts`, filters)
                    .then((response: any) => {
                        //TODO: los filtros de backend no están implementados. Implementación en front por ahora
                        if (response?.data?.length) {
                            response.data = response.data.filter((clientAccount: any) => {
                                // agentId:"1"
                                // agentTypeId:"2"
                                // currencyTypeId:"3950"
                                if (filters.currencyTypeId && clientAccount?.currency?.id != filters.currencyTypeId) {
                                    return false;
                                }
                                if (filters.agentTypeId && clientAccount?.agent?.agentType?.id != filters.agentTypeId) {
                                    return false;
                                }
                                if (filters.agentId && clientAccount?.agent?.id != filters.agentId) {
                                    return false;
                                }
                                return true;
                            });
                        }
                        return response;
                    });
            },
            insert: (data: any) => {
                return api.post(`${url}/clientAccount/insert`, data);
            },
            delete: (id: any) => {
                return api.post(`${url}/clientAccount/delete`, { id: id, isAvailable: false });
            },
            enable: (id: any) => {
                return api.post(`${url}/clientAccount/delete`, { id: id, isAvailable: true });
            },
        },
        Client: {
            get: () => {
                return api.get(`${url}/clients`, {});
            },
            getClient: () => {
                return api.get(`${url}/client`, {});
            },
            insert: (data: any) => {
                return api.post(`${url}/client/insert`, data);
            },
            update: (data: any) => {
                return api.post(`${url}/client/update`, data);
            },
            remove: (data: any) => {
                return api.post(`${url}/client/delete`, data);
            },
            composicionCliente: (filters: any) => {
                let listOfIntegerArrays = ['CurrencyIdList'];
                let queryString = getAsQueryString(filters, listOfIntegerArrays);

                return api.get(`${url}/totalValue/client-percentage?${queryString}`, null);
            },
        },
        CommercialAgent: {
            get: () => {
                return api.get(`${url}/commercialAgent`, {});
            },
            insert: (data: any) => {
                return api.post(`${url}/commercialAgent/insert`, data);
            },
            update: (data: any) => {
                return api.post(`${url}/commercialAgent/update`, data);
            },
            remove: (data: any) => {
                return api.post(`${url}/commercialAgent/delete`, data);
            },
        },
        Connections: {

        },
        Groups: {
            get: () => {
                return api.get(`${url}/groups`, {});
            },
            insert: (data: any) => {
                return api.create(`${url}/group/insert`, data);
            },
            update: (data: any) => {
                return api.post(`${url}/group/update`, data);
            },
            delete: (id: number) => {
                return api.post(`${url}/group/delete`, { id: id, isAvailable: false });
            },
            evolucion: (data: any) => {
                //TODO: el fake backend no intercepta llamadas get, por eso se usa un create. cambiar por get
                return api.create(`${url}/group/evolucion`, data);
            },
            composicion: (data: any) => {
                //TODO: el fake backend no intercepta llamadas get, por eso se usa un create. cambiar por get
                return api.create(`${url}/group/composicion`, data);
            },
            indicadores: (data: any) => {
                //TODO: el fake backend no intercepta llamadas get, por eso se usa un create. cambiar por get
                return api.create(`${url}/portfolio/indicadores`, data);
            },
        },
        Instrumentos: {
            getInstruments: (instrumentType: number = 9) => {
                return api.get(`${url}/instruments`, { instrumentType: instrumentType, takeElement: 300, isAvailable: true });
            },
            getInstrumentsOData: (odataFilters: any) => {
                let promise = api.get(`${url}/instruments/odata`, odataFilters);
                interceptors.Instrumentos.ModifyODataInstruments(promise);
                return promise;
            },
        },
        Movimientos: {
            get: (odataFilters?: any) => {
                let promise = api.get(`${url}/transaction/get`, odataFilters);
                //TODO: en la consulta de movimiento se tienu modificar la cantidad a su valor absoluto porque en la inserción back inserta en negativo para débito
                interceptors.Movimientos.ModifyQuantity(promise);
                return promise;
            },
            getAgentAccounts: () => {
                let promise = api.get(`${url}/transaction/agent-account`, {});
                interceptors.Movimientos.MapAgentsAccounts(promise);
                return promise;
            },
            insertBatch: (data: any) => {
                return api.post(`${url}/insert-batch`, data);
            },
            update: (data: any) => {
                return api.post(`${url}/update-numero-operacion`, data);

            }
        },
        Organizations: {

        },
        Params: {
            get: () => {
                return api.get(`${url}/params`, {});
            },
            getTaxPayerTypes: () => {
                return api.get(`${url}/params/taxPayerTypes`, {});
            },
            getCountries: () => {
                return api.get(`${url}/params/countries`, {});
            },
            getConcepts: () => {
                return api.get(`${url}/params/concepts`, {});
            },
            getCurrencyTypes: () => {
                return api.get(`${url}/params/currencyTypes`, {});
            },
            getLanguages: () => {
                return api.get(`${url}/params/languages`, {});
            },
            getRoles: () => {
                return api.get(`${url}/params/userRoles`, {});
            },
            getTransactionTypes: () => {
                return api.get(`${url}/params/transactionTypes`, {});
            },
            getInstrumentTypes: () => {
                return api.get(`${url}/params/instrumentTypes`, {});
            },
            getMarkets: () => {
                return api.get(`${url}/params/markets`, {});
            },
            getAgents: () => {
                return api.get(`${url}/params/agents`, {});
            },
            getAgentTypes: () => {
                return api.get(`${url}/params/agentTypes`, {});
            },
            getAppVersionNumber: () => {  // Obtiene el número de versión de la app
                let queryString = getAsQueryString({ name: appVersionNumber}, []);
                return api.get(`${url}/params/appVersionNumber?${queryString}`, null);
            },
            updateAppCurrentVersionNumber: (data: any) => { // Permite actualizar el número de versión de la app
                return api.post(`${url}/params/updateVersionNumber`, data);
            },
        },
        Portfolio: {
            get: () => {
                return api.get(`${url}/portfolio`, {});
            },
            insert: (data: any) => {
                return api.create(`${url}/portfolio/insert`, data);
            },
            hasWarnings: () => {
                return api.get(`${url}/portfolio/hasWarnings`, {});
            },
            update: (data: any) => {
                return api.create(`${url}/portfolio/update`, data);
            },
            delete: (data: any) => {
                return api.create(`${url}/portfolio/delete`, data);
            },
            totalValuePortfolio: (data: any) => {
                let promise = api.get(`${url}/totalValue/portfolio`, data);
                interceptors.Portfolio.ModifyOrderTotalValue(promise);
                return promise;
            },
            totalValueGroup: (data: any) => {
                let promise = api.get(`${url}/totalValue/portfolioGroup`, data);
                interceptors.Portfolio.ModifyOrderTotalValue(promise);
                return promise;
            },
            totalValueClient: (data: any) => {
                let promise = api.get(`${url}/totalValue/client`, data);
                interceptors.Portfolio.ModifyOrderTotalValue(promise);
                return promise;
            },
            evolucion: (data: any) => {
                //TODO: el fake backend no intercepta llamadas get, por eso se usa un create. cambiar por get
                return api.get(`${url}/totalValue/portfolio-percentage`, data);
            },
            composicionPortFolio: (filters: any) => {
                let listOfIntegerArrays = ['CurrencyIdList', 'PortfolioIdList'];
                let queryString = getAsQueryString(filters, listOfIntegerArrays);

                //La URL ya tendrá todos los filtros
                let _url = `${url}/totalValue/portfolio-percentage?${queryString}`;
                return api.get(_url, null);
            },
            composicion: (data: any) => {
                //TODO: el fake backend no intercepta llamadas get, por eso se usa un create. cambiar por get
                return api.create(`${url}/portfolio/composicion`, data);
            },
            indicadores: (data: any) => {
                //TODO: el fake backend no intercepta llamadas get, por eso se usa un create. cambiar por get
                return api.create(`${url}/portfolio/indicadores`, data);
            },
            portfolioDailyPerformance: (data: any) => {
                return api.get(`${url}/Dailyperformance/portfolio`, data);
            }
        },
        Stats: {

        },
        Report: {
            positions: (filters: any) => {
                let listOfIntegerArrays = ['agentIdList', 'portfolioIdList', 'clientAccountIdList', 'instrumentsTypeIdList', 'instrumentIdList'];
                let queryString = getAsQueryString(filters, listOfIntegerArrays);

                //La URL ya tendrá todos los filtros
                let _url = `${url}/report/positions?${queryString}`;
                return api.get(_url, null);
            },
        },
        RdConnection: {
            descargarInstrumentos: () => {
                return api.post(`${url}/rdconnection/download-instrument-data`, {});
            },
            descargarPrecios: () => {
                return api.post(`${url}/rdconnection/download-prices`, {});
            },
            procesarUltimaInformacionDescargada: (id: any | null = null, fecha: Date | null = null) => {
                let data: any = {
                    logLevel: "Information",
                    showWarnings: true,
                    saveChanges: true,
                };
                if (id) {
                    data.impactarPreciosParaUnaFechaEspecifica = {
                        id: id,
                        date: fecha    
                    };
                }
                return api.post(`${url}/rdconnection/process-data`, data);
            },
            getPrimaryReferenceDownloadedInstrumentTypes: () => {
                return api.get(`${url}/rdconnection/primary-reference-downloaded-instrument-types`, {})
                    .then((response) => {
                        if (response?.data?.length) {
                            response.data = response.data.map((instrumentType: any) => {
                                instrumentType.downloadedDateTime = moment(instrumentType.downloadedDateTime);
                                return instrumentType;
                            });
                        }
                        return response;
                    });
            },
            getPrimaryReferenceDownloadedInstrumentTypesDetail: (id: string) => {
                return api.get(`${url}/rdconnection/primary-reference-downloaded-instrument-types-detail`, { id: id });
            },
            getPrimaryReferenceDownloadedInstruments: () => {
                return api.get(`${url}/rdconnection/primary-reference-downloaded-instruments`, {});
            },
            getPrimaryReferenceDownloadedInstrumentsDetail: (id: string) => {
                return api.get(`${url}/rdconnection/primary-reference-downloaded-instrument-detail`, { id: id });
            },
            getPrimaryReferenceDownloadedPrices: () => {
                return api.get(`${url}/rdconnection/primary-reference-downloaded-prices`, {});
            },
            getPrimaryReferenceDownloadedPricesDetail: (id: string) => {
                return api.get(`${url}/rdconnection/primary-reference-downloaded-prices-detail`, { id: id });
            },
            getListReferenceDataForProcess: () => {
                return api.get(`${url}/rdconnection//get-last-reference-data-for-process`, {});
            },
            exportPrimaryReferenceCsvList: (selectedIds: any, backupAndDelete: boolean) => {
                let queryString = 'ids=' + selectedIds.join('&ids=') + '&deleteChildrenAfterDownload=' + backupAndDelete;
                return api.getFile(`${url}/rdconnection/export-primary-reference-csv-list?${queryString}`, undefined);
            },
        },
        Users: {
            get: () => {
                return api.get(`${url}/users`, {});
            },
            getUser: () => {
                return api.get(`${url}/user`, {});
            },
            getUsers: (id: number) => {
                return api.get(`${url}/user/client`, { id: id });
            },
            update: (data: any) => {
                return api.post(`${url}/user/update`, data);
            },
            invite: (data: any) => {
                return api.post(`${url}/user/invite`, data);
            },
            remove: (id: any, auth0Id: any, state: boolean) => {
                return api.post(`${url}/user/delete`, {
                    id: id,
                    auth0Id: auth0Id,
                    isAvailable: state
                });
            },
            changePass: (data: any) => {
                return api.post(`${url}/user/change_password`, data);
            },
            invitation: (user: User, state: boolean) => {
                let data: any = {
                    firstname: user?.firstname,
                    lastname: user?.lastname,
                    clientId: user?.client?.id,
                    email: user?.email,
                    languageId: user?.language?.id,
                    auth0Id: user?.auth0Id,
                    roleId: user?.role?.id,
                    acceptNotifications: user?.acceptNotifications
                };
                return (state) ?
                    api.post(`${url}/user/resend`, data) :
                    api.post(`${url}/user/reject`, data);
            },
        },
        Prices: {
            get: (filters: Cotizaciones_RequestConsulta) => {
                return api.get(`${url}/prices`, filters);
            },
            insert: (data: any) => {
                return api.post(`${url}/prices/insert`, data);
            },
            delete: (id: any) => {
                return api.post(`${url}/prices/delete`, { id: id });
            },
            downloadTemplate: () => {
                return api.get(`${url}/prices/download-import-template`, {});
            },
            importCsv: (data: any) => {
                return api.createWithFile(`${url}/prices/import`, data);
            }
        },
        TotalValue: {
            recalculate: (date: any) => {
                return api.post(`${url}/TotalValue/recalculate`, { date: date });
            },
        },
        Comercial: {
            get: () => {
                return api.get(`${url}/commercialAgent`, {});
            },
            insert: (data: any) => {
                return api.post(`${url}/commercialAgent/insert`, data);
            },
            update: (data: any) => {
                return api.post(`${url}/commercialAgent/update`, data);
            },
            remove: (data: any) => {
                return api.post(`${url}/commercialAgent/delete`, data);
            },
        },
        Auth0: {
            get: () => {
                return api.get(`${url}/user/auth0`, {});
            },
            firstlogin: () => {
                return api.post(`${url}/user/firstlogin`, {});
            }
        },
        Process: {
            get: () => {
                return api.get(`${url}/process/get`, {});
            }
        },
        ProcessExecutionStatus: {
            get: () => {
                return api.get(`${url}/processExecutionStatus/get`, {});
            }
        },
        ProcessExecution: {
            getLastN: (filter: ProcessExecution_GetRequest): any => {
                return api.get(`${url}/processExecution/getlastn`, filter);
            }
        }
    };

    //TODO dCestare: este código se debe a un extraño comportamiento en la API al pasar un array de enteros en un endpoint tipo GET. Ver esto: https://stackoverflow.com/a/11100414
    function getAsQueryString(filters: any, listOfIntegerArrays: string[]) {
        let listOfKeys = Object.keys(filters);
        //Obtengo todos los filtros que no son listas de integers y las agrego al query string
        let queryString = filters ? listOfKeys
            .filter((key) => !listOfIntegerArrays.map(x => x.toLowerCase()).includes(key.toLowerCase()))
            .map((key) => key + '=' + filters[key])
            .join('&') : '';

        //tratamiento especial para los filtros que son listas de integers
        listOfIntegerArrays.forEach(key => {
            //Recorro los filtros que son arrays de enteros
            let arrayOfIntegers = filters[key];
            if (arrayOfIntegers?.length) {
                //si tienen un valor, los concateno de la siguiente forma: portfolioIdList=1&portfolioIdList=2&...
                queryString += '&' + arrayOfIntegers
                    .map((_integer: number) => key + '=' + _integer)
                    .join('&');
            }
        });
        return queryString;
    }
}
